const config = {
    "broker": "1",
    "name": "Spanish",
    "registrationBundle": "spanish",
    "defaultCountryName": "Colombia",
    "defaultCountryCode": "ES",
    "fxgeoip_code": "ES",
    languageCode: 'es',
    languageName:'Spanish',
    spokenLanguageCode:'es',
    direction:'ltr',
    footerDisclaimer:'720',
    brand: 'iforex'
};
module.exports = config;